<template>
    <Loader :loader="loader" />
   
    <div class="inner-wrap2">
      <!-- <p style="font-size: 28px; font-family: 'Anton-Regular';">coming soon</p> -->
     <h3>Users by month</h3>
      <div :class="['criteria', uFnCheck(11) ? 'v-sa':'' ]">
        <div class="control-wrap p-year">
            <span>Year: &nbsp;</span>
              <vue-select 
                v-model="year" 
                :options="years" 
                label-by="value" 
                value-by="no" 
                :blur="onDateChange"
                close-on-select />


            <span>Pagination: </span>
            <label class="rad-label">
                  <input type="checkbox" class="rad-input" name="pagination" v-model="pagination" />
                  <div class="rad-design"></div>
            </label>

            <span>To this day: </span>
            <label class="rad-label" title="with/without future data">
                  <input type="checkbox" class="rad-input" name="toThisDay" v-model="toThisDay" @change="onDateChange" />
                  <div class="rad-design"></div>
            </label>
        </div>

        <div v-if="uFnCheck(11)" class="control-wrap agency">
              <span>Agency: &nbsp;</span>
              <vue-select 
              v-model="agencyId" 
              :options="agencies" 
              label-by="Description" 
              value-by="Id" 
              :blur="onDateChangeAgency"
              close-on-select />  
                                
        </div>  

        <div class="control-wrap excel">
            <vue-excel-xlsx class="d-btn ex-btn"
            :data="table1rows"
            :columns="table1columns"
            :file-name="filename"
            :file-type="'xlsx'"
            :sheet-name="'UsersByMonth'"
            :disabled="table1rows === null"
            title="export to Excel"
            >
            <i class="fas fa-file-excel"></i>
          </vue-excel-xlsx>
        </div>
         
      </div>
      

      <div class="center-pan">

        <vue-good-table
            :columns="table1columns"
            :rows="table1rows"
            :row-style-class="rowStyleClass"
            :line-numbers="false"
            :search-options="{enabled: true}"
            :sort-options="{enabled: true}"  
            @cell-click="cellClicked"
            :pagination-options="{
              enabled: pagination,
              mode: 'records'
            }" 
                          
          >
            <template v-slot:emptystate>
                No data
            </template>

            
          </vue-good-table>
      </div>
        

    </div>
 
  
</template>

<script>
import shared from "@/shared";
import moment from "moment";
import reportService from "@/services/reports.js";
import Loader from '../../components/Loader.vue';
import adminService from "@/services/admin.js";
// import { DoughnutChart } from 'vue-chart-3';
// import { BarChart } from 'vue-chart-3';
// import { Chart, registerables } from "chart.js";

//Chart.register(...registerables);

export default {
  components: {
      Loader, 
      //DoughnutChart, 
      //BarChart 
  },
  name: "App",
  
  

  data: function () {
    return {
      year: 2022,
      agencyId: this.$store.getters.userData.agencyId,
      agencies: [],
      showNav: true,
      error: null,
      loader: false,
      pagination: false,
      toThisDay: false,
      filename: 'filename',
      years: [],
      table1columns: [
        {
          label: 'Team',
          field: 'team',
          tdClass: 'text-f14'
        },
        {
          label: 'Name',
          field: 'name',
          tdClass: 'text-f14 name-lnk'
        },
        { label: 'January', field: 'jan', type: 'number', tdClass: 'text-f14'},
        { label: 'February', field: 'feb', type: 'number', tdClass: 'text-f14'},
        { label: 'March', field: 'mar', type: 'number', tdClass: 'text-f14'},
        { label: 'April', field: 'apr', type: 'number', tdClass: 'text-f14'},
        { label: 'May', field: 'may', type: 'number', tdClass: 'text-f14'},
        { label: 'June', field: 'jun', type: 'number', tdClass: 'text-f14'},
        { label: 'July', field: 'jul', type: 'number', tdClass: 'text-f14'},
        { label: 'August', field: 'aug', type: 'number', tdClass: 'text-f14'},
        { label: 'September', field: 'sep', type: 'number', tdClass: 'text-f14'},
        { label: 'October', field: 'oct', type: 'number', tdClass: 'text-f14'},
        { label: 'November', field: 'nov', type: 'number', tdClass: 'text-f14'},
        { label: 'December', field: 'dec', type: 'number', tdClass: 'text-f14'},
        { label: 'Total', field: 'sum', type: 'number', tdClass: 'text-f14-b'}
      ],
      table1rows: []
      
    };
  },

  created() {
    this.yearList = shared.yearList;
    this.uFnCheck = shared.uFnCheck;

    this.years = this.yearList();
    this.year = this.$store.getters.getCurrYear;

    // console.log('years');
    // console.log(this.years);
    // console.log(this.year);

  },
 
  mounted() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    //this.yearList = shared.yearList;
    this.CookieCheckGoToLogin();   

    this.filename = 'UsersByMonth-year-'+this.year+'-'+moment().format("YYMMDD.HHmmss");

    
    const data = {ResultName: "AgencyList"};
    adminService
          .uniRap(data)
          .then((response) => {

          if (response.status === 200) {                  
    
                this.agencies = response.data;    

                // console.log('agencies');
                // console.log(this.agencies);

                this.agencies.unshift({Id: 0, Description: '-- All Agencies --'});

                  setTimeout(() => {                         
                      
                      this.agencyId = parseInt(this.$store.getters.userData.agencyId);
                      //this.dashboardData();
                },480);
          }
          
          })
          .catch((e) => {
          this.error = e.message || "Something went wrong!";
          
          
          if (e.response.status === 401) {
                this.CookieRemove();
          }
          });


    this.onDateChange();
   
  },
   
  methods: {

    cellClicked(params) {
        if (params.column.field === 'name' && params.row.userId !== undefined) {
            // console.log(params.row.name); 
            // console.log(params.row.userId); 
            this.$store.commit('setRapDashUserId', params.row.userId);
            this.$store.commit('setRapDashUserName', params.row.name);
            this.$router.push({ name: 'Dashboard'});
            //this.$router.push({ name: 'Dashboard', state: { userId: params.row.userId }});
                      
        }
    },

    onDateChangeAgency(){
      console.log('data change agency');
       this.onDateChange();
    },

  
    onDateChange() {
      this.loader = true;
      this.filename = 'UsersByMonth-year-'+this.year+'-'+moment().format("YYMMDD.HHmmss");

      console.log('data change');

      const data = {
        "year": this.year,
        "agencyId": this.agencyId,
        "toThisDay": this.toThisDay ? 1 : 0
      };

      // console.log(data);

      reportService
      .rap2(data)
      .then((response) => {

        if (response.status === 200) {

                    
          this.table1rows = response.data;

          for (const i of this.table1rows) {

            const newName = i.name.split('#');
            i.name = newName[0];
            i.status = newName[1];
            i.userId = newName[2];

            let tmpSum = 0.0;

            // for (const value of Object.values(i)) {
            //   if(!isNaN(value)) {
            //     tmpSum += value;
            //   }              
            // }

            for (const [key, value] of Object.entries(i)) {
              if(key !== 'userId' && !isNaN(value)) {
                tmpSum += value;
              }              
            }


            i.sum = tmpSum;  
          }

          this.table1rows = this.table1rows.filter(function(el) { return !(el.sum == 0 && el.status == 'Inactive'); });


          //  console.log("----table1rows----");
          //  console.log(this.table1rows);
        }
        
      })
      .catch((e) => {
        this.error = e.message || "Something went wrong!";
        
        
        if (e.response.status === 401) {
            this.CookieRemove();
        }
      })
      .finally(() => {
        this.loader = false;
        
        //this.tableArr();

      });

    },

    rowStyleClass(row) {

      return row.name == '-Total-' ? 'cl-total' : (row.status == 'Inactive' ? 'cl-row-inactive' : 'cl-row');
    },

   
    // tableArr() {
    //   //this.table1rows = this.rapJobsData;

    //   if (this.table1rows.length > 1) {

    //     let sum = 0.0;
    //     this.table1rows.forEach(element => {
    //       sum = sum + element.hours;
          
    //     });
    //     this.table1rows.push( { "jobNo": "", "jobName": "<b>Total</b>", "hours": "<b>"+sum+"</b>" });
    //   }

    // },

    
 

  },
  computed: {
    
  }

}
</script>

<style >

.inner-wrap2 {
  text-align: center;

}



.inner-wrap2 h3 {
  font-family: 'Anton-Regular';
  font-size: 22px;
  letter-spacing: 2px;
}

.inner-wrap2 .dp__main {
  width: 260px;
}


.inner-wrap2 .criteria {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 26px;
}


.inner-wrap2 .criteria span { 
  margin-left: 10px; 
  }

.inner-wrap2 .vue-select {
  border-radius:initial;
  border-radius: 4px;
  z-index: 2;
  border: 1px solid #e3e3e3;
  background-color: white;
  width: 154px;
}

.inner-wrap2 .vue-input {
  padding: 10px 6px 10px 6px;
}
.inner-wrap2 .vue-input input {
  background-color: transparent;
  font-size: 16px;
}

.inner-wrap2 .vue-dropdown {
  border: 1px solid #e3e3e3;
}

.inner-wrap2 .vue-input .icon.arrow-downward  {
  border-width: 8px 5px 0;
}

.inner-wrap2 .vue-dropdown-item.highlighted {
    background-color: #fff8e3;
}

.inner-wrap2 .vue-dropdown-item.selected.highlighted {
    background-color: #fceec2;
}

/* .dialog-box.edit .vue-select {
  width: 100%;

} */


.inner-wrap2 .center-pan {
    box-shadow: 0px 3px 16px -5px rgb(120 122 123);
    position: relative;
    background-color: white;
    margin: 10px;
    margin-left: 20px;
    padding: 18px;
    min-height: 380px;
   
}

.inner-wrap2 .text-f14 {
    font-size: 14px;
}

.inner-wrap2 .text-f14-b {
    font-size: 14px;
    font-weight: bold;
}

.inner-wrap2 .name-lnk {
  cursor: pointer;
}

.inner-wrap2 .name-lnk span {
  text-decoration: underline;  
}

.inner-wrap2 .cl-total .name-lnk {
  cursor: initial !important;
}

.inner-wrap2 table.vgt-table .cl-total .name-lnk span {
  text-decoration: none !important;
  
}

.inner-wrap2 table.vgt-table {
  font-size: 14px;
}

.inner-wrap2 table.vgt-table .cl-total {
  background-color: #fafaf1;
}

.inner-wrap2 table.vgt-table .cl-row-inactive td span {
  color: #d1d1d1 !important;
}



.inner-wrap2 .vgt-wrap__footer {
  padding: 10px 16px;
}

.inner-wrap2 .vgt-wrap__footer .footer__navigation, 
.inner-wrap2 .vgt-wrap__footer .footer__row-count__label,
.inner-wrap2 .vgt-wrap__footer .footer__navigation__page-btn span {
  font-size: 14px;
}


.control-wrap {
  display: flex;
  justify-content:flex-end;
  flex-grow: 1;
  align-items: center;
}

.v-sa .control-wrap.p-year {
  flex-basis: 53%;
  padding-right: 22px;
}

.v-sa .control-wrap.agency {
    flex-basis: 34%;
    justify-content: flex-start;
    padding-left: 16px;
}

.v-sa .control-wrap.excel {
    flex-basis: 10%;
}

.control-wrap .rad-label {
    padding: 0px 0 0 6px;
}

.control-wrap.p-year .rad-label {
    padding: 0px 6px 0 6px;
}

.control-wrap.excel {
  padding-right: 5px;
}

.control-wrap.excel .d-btn {
  width: auto;
  padding: 7px 18px;
  font-size: 18px;
}


.dark-mode .inner-wrap2 table.vgt-table .cl-row-inactive td span {
  color: #808080 !important;
}


</style>